<template>
<div class="container" id="page">
<h1 class="text-center">Privacy Policy</h1>

<p>Introduction</p>

<p>Welcome to Competition Mania Ltd’s privacy policy. </p>

<p>Competition Mania Ltd respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we process and look after your personal data when you visit our website (<a href="http://www.competitionmania.co.uk" >www.competitionmania.co.uk</a>) , regardless of where you visit from. </p>

<p>It also tells you about your privacy rights and how the law protects you.</p>

<p>1. Important Information and Who We Are</p>

<p>Purpose of this privacy policy</p>

<p>This privacy policy aims to give you information on how Competition Mania Ltd collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletters, purchase any products or services or take part in a prize draw or competition.</p>

<p>This website is not intended for children and we do not knowingly collect data relating to children.</p>

<p>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</p>

<p>Controller</p>

<p>Competition Mania Ltd is the controller and responsible for your personal data (collectively referred to as Competition Mania, ‘’we’’, ‘’us’’, or ‘’our’’ in this privacy policy).</p>

<p>If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact us at <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a>.</p>

<p>You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority or data protection issues (<a href="http://www.ico.org.uk" >www.ico.org.uk</a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance. </p>

<p>Changes of the privacy policy and your duty to inform us of changes</p>

<p>We keep our privacy policy under regular review. This version was last updated on 7th of March, 2021.</p>

<p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

<p>Third-party links</p>

<p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>

<p>2. The Data We Collect About You</p>

<p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). </p>

<p>We may collect, use, store and transfer different kinds of personal data about you, which we have grouped together as follows:</p>

<ul>
<li><p>Identity Data includes [first name, last name, username or similar identifier, title, date of birth].</p>

</li>
<li><p>Contact Data includes billing address, email address and telephone numbers.</p>

</li>
<li><p>Financial Data includes bank account and payment card details.</p>

</li>
<li><p>Transaction Data includes details about payments to and from you and other details of products and services you have purchased from us.</p>

</li>
<li><p>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</p>

</li>
<li><p>Profile Data includes your username and password, purchases or orders made by you, your interests, preferences.</p>

</li>
<li><p>Usage Data includes information about how you use our website, products and services.</p>

</li>
<li><p>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>

</li>
</ul>

<p>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>

<p>We may collect the minimum amount of personal data to enable you to enter into any prize draw and competition and allow us to run the promotion. If you are a winner, it may be necessary to collect more detailed information from you in order to award your prize to you. You will be notified of this at the time we notify you if you have won.</p>

<p>If you fail to provide personal data</p>

<p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services or to enter you into a competition). In this case, we may have to cancel a product or service you have with us or refuse your entry to a competition. We will notify you if this is the case at the time.</p>

<p>3. How is Your Personal Data Collected?</p>

<p>We use different methods to collect data from and about you including through:</p>

<ul>
<li><p>Direct interactions. You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>

</li>
<ul>
<li><p>create an account on our website;</p>

</li>
<li><p>request marketing to be sent to you;</p>

</li>
<li><p>enter a competition, promotion or survey; or</p>

</li>
<li><p>give us feedback or contact us.</p>

</li>
</ul>

<li><p>Automated technologies or interactions. As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies.</p>

</li>
<li><p>Third parties or publicly available sources. We will receive personal data about you from various third parties as set out below:</p>

</li>
<li><p>Technical Data from the following parties:</p>

</li>
<ul>
<li><p>(a) analytics providers such as Google based outside the EU.</p>

</li>
</ul>

</ul>

<p>4. How We Use Your Personal Data</p>

<p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>

<ul>
<li><p>Where we need to perform the contract we are about to enter into or have entered into with you. A legally binding contract is formed between us when you purchase goods or services from us or you enter into a competition.</p>

</li>
<li><p>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</p>

</li>
<li><p>Where we need to comply with a legal obligation.</p>

</li>
</ul>

<p>If you are the winner of the competition you will be invited to participate in publicity exercises relating to the competition. Your participation is not compulsory but would be greatly appreciated by us. If you do not wish to participate, your participation in the competition or chances of winning will not be affected in any way. However, if you do not wish to participate in any publicity and decline to be named as the winner, we may still have to provide your details to the Advertising Standards Authority (ASA) as proof that the competition has been properly administered and the prize awarded. This is a legal requirement we must comply with. If you require detail of how the ASA will use and process your personal data, please let us know.</p>

<p>Marketing</p>

<p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. This may include, but is not limited, to the use of your name, county, and personal image used to present your awarded prize. </p>

<p>Promotional offers from us</p>

<p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</p>

<p>You will receive marketing communications from us if you have requested information from us or purchased goods or services from us, entered a competition or prize draw and you have not opted out of receiving that marketing.</p>

<p>Cookies</p>

<p>You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</p>

<p>Change of purpose</p>

<p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>

<p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>

<p>5. Data Security </p>

<p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>

<p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

<p>6. Data Retention</p>

<p>How long will you use my personal data for?</p>

<p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>

<p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>

<p>Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from us.</p>

<p>In some circumstances you can ask us to delete your data: see “Your legal rights” below for further information.</p>

<p>In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>

<p>7. Your Legal Rights</p>

<p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. If you wish to exercise any of these rights, please contact us.</p>

<p>No fee usually required</p>

<p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>

<p>What we may need from you</p>

<p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

<p>Time limit to respond</p>

<p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>

<p>You have the right to:</p>

<p>Request access to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>

<p>Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>

<p>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>

<p>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>

<p>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</p>

<ul>
<li><p>If you want us to establish the data’s accuracy.</p>

</li>
<li><p>Where our use of the data is unlawful but you do not want us to erase it.</p>

</li>
<li><p>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</p>

</li>
<li><p>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>

</li>
</ul>

<p>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>

<p>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>

<br>
</div>
  <Footer/>
</template>



<script>
import Footer from '../components/global/Footer'

export default {
    components: {
        Footer
    }
}
</script>